/* Ensure the body and html take full height */
html, body {

  }
  
  .page-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    min-height: 100%;
    width: 100%;
  }
  
  .title-container, .description-container, .scene-container, .button-container {
    width: 850px;
    text-align: center;
  }

  .description-container {
    line-height: 0.3;
  }
  
  .scene-container {
    height: 480px;
    margin: 20px 0;
    border:1px solid #5555;
  }
  
  .button-container {
    margin-top: 20px;
  }
  
  footer.footer {
    position: fixed;
    bottom: 10px;
    left: 10px;
    font-size: 14px;
  }
  